import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css?v=2.1";
import './shared/fonts.css';
import './shared/buttonStyles.css?v=2.1';
import './shared/typography.css?v=';
import Layout from "./shared/layout/layout";
import NavigationBar from "./shared/navigationBar/navigationBar";
import { AuthProvider } from "./component/auth/auth";
import LoadingSpinner from './component/styledComponent/spinner/loadingSpinner';
import { HelmetProvider } from 'react-helmet-async';
// Lazy load components
const Home = lazy(() => import("./pages/home/home"));
const Registration = lazy(() => import("./pages/home/registration"));
const SearchPlant = lazy(() => import("./pages/search/searchPlant"));
const PlantDetail = lazy(() => import("./pages/plantDetail/plantDetail"));
const InspirationNews = lazy(() => import("./pages/inspirationNews/inspirationNews"));
const InspirationNewsDetail = lazy(() => import("./pages/inspirationNews/inspirationNewsDetail"));
const Team = lazy(() => import("./pages/team/team"));
const ScanPage = lazy(() => import("./pages/scan/scanPage"));
const ScanResultPage = lazy(() => import("./pages/scan/result/scanResultpage"));
const GardifyScanResult = lazy(() => import("./pages/scan/result/gardifyScanResult"));
const PlantDoc = lazy(() => import("./pages/plantDoc/plantDoc"));
const PlantDocDetail = lazy(() => import("./pages/plantDoc/plantDocDetail"));
const PasswordReset = lazy(() => import("./pages/home/passwordReset"));
const ScanPreview = lazy(() => import("./pages/scan/scanPreview"));
const AGB = lazy(() => import("./pages/dataPrivacy/agb"));
const Datenschutz = lazy(() => import("./pages/dataPrivacy/datenschutz"));
const Impressum = lazy(() => import("./pages/dataPrivacy/impressum"));
const ConfirmEmail = lazy(() => import("./pages/home/confirmEmail"));

function App({ Router, location }) {
  return (
    <AuthProvider>
      <HelmetProvider>

        <Layout>
          <Router location={location}>
            <Suspense fallback={<LoadingSpinner />}>
              <NavigationBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Anmeldung" element={<Registration />} />
                <Route path="/inspiration" element={<InspirationNews />} />
                <Route path="/inspiration/post/:id" element={<InspirationNewsDetail />} />
                <Route path="/search/plants" element={<SearchPlant />} />
                <Route path="/pflanze/:id/:name?" element={<PlantDetail />} />
                <Route path="/uns" element={<Team />} />
                <Route path="/scan" element={<ScanPage />} />
                <Route path="/ScanPreview" element={<ScanPreview />} />
                <Route path="/scan/results" element={<ScanResultPage />} />
                <Route path="/GardifyScanResult/:name?" element={<GardifyScanResult />} />
                <Route path="/Pflanzen-Doc" element={<PlantDoc />} />
                <Route path="/Pflanzen-Doc/:id" element={<PlantDocDetail />} />
                <Route path="/resetPassword" element={<PasswordReset />} />
                <Route path="/agb" element={<AGB />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/confirmEmail" element={<ConfirmEmail />} />
              </Routes>
            </Suspense>
          </Router>
        </Layout>
      </HelmetProvider>

    </AuthProvider>
  );
}

export default App;
