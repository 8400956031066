export const Network = () => {

  const fetchPromise = (method, url, body) => {
    return fetch(url, {
      method: method,
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: body,
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("token");
          return;
        } else if (response.status === 500) {
          return;
        } else if (response.status === 404) {
          return;
        }

        // //console.log("NETWORK_LOG: RESPONSE RECEIVED", response);
        return response.json();
      })
      .then((json) => {
        // //console.log("NETWORK_LOG: JSON PARSED", json);
        return json;
      })
      .catch((error) => {
        //console.log("NETWORK_LOG: ERROR PARSING JSON DATA", error);
        return error;
      });
  }

  const fetchPromiseDownload = (method, url) => {
    return fetch(url, {
      method: method,
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.clear();
        }
        return response.blob();
      })
      .then((blob) => {
        return blob;
      })
      .catch((error) => console.log("NETWORK_LOG: ERROR Downloading", error));
  }


  return {
    getRequest: (url) => fetchPromise("GET", url),
    postRequest: (url, body) => fetchPromise("POST", url, JSON.stringify(body)),
    putRequest: (url, body) => fetchPromise("PUT", url, JSON.stringify(body)),
    deleteRequest: (url) => fetchPromise("DELETE", url),
    downloadRequest: (url) => fetchPromiseDownload("GET", url)
  };

}


