import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css?v=2.1';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');

if (process.env.NODE_ENV !== 'production') {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <App Router={BrowserRouter} />
        </React.StrictMode>
    );
} else {
    hydrateRoot(container, <App Router={BrowserRouter} />);
}

reportWebVitals();
