import React from "react";
import './loadingSpinner.css?v=2.1';

const LoadingSpinner = props => {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">

            </div>
        </div>
    );
}

export default LoadingSpinner;