import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

const AuthContext = React.createContext(null);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const ProtectedRoute = ({ children }) => {
  const { setLocalStorage, token } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLocalStorage(localStorage.getItem("token"));
    }
  }, [setLocalStorage]);

  if (!token && (typeof window === "undefined" || localStorage.getItem("token") == null)) {
    return <Navigate to="/" replace state={{ path: location.pathname }} />;
  }

  return children;
};

export const NonLoginRoute = ({ children }) => {
  const location = useLocation();
  const { token } = useAuth();

  if (token) {
    const redirectPath = (typeof window !== "undefined" && localStorage.getItem("token") !== null)
      ? location.state?.path || "/"
      : "/Home";
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("token");
    }
    return null;
  });

  const handleLogin = (data) => {
    setToken(data.token);
    if (typeof window !== "undefined") {
      localStorage.setItem("token", data.token);
    }
  };

  const handleLocalStorage = (token) => {
    setToken(token);
  };

  const handleLogout = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("token");
    }
    setToken(null);
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    setLocalStorage: handleLocalStorage,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
